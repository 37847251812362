import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Image = makeShortcode("Image");
const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const SourceWords = makeShortcode("SourceWords");
const Flex = makeShortcode("Flex");
const Heading = makeShortcode("Heading");
const Donut = makeShortcode("Donut");
const Twitch = makeShortcode("Twitch");
const TwitterTimeline = makeShortcode("TwitterTimeline");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><a parentName="h1" {...{
        "href": "#about"
      }}>{`About`}</a></h1>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
      mb: 4
    }} mdxType="Grid">
  <Box mdxType="Box">
    <Image src={`/images/flowers.jpg`} sx={{
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }} mdxType="Image" />
  </Box>
  <Box mdxType="Box">
    <Text mdxType="Text">
      I was born and raised in Burbank, California to Roman-Catholic immigrant
      parents. Grew up playing StarCraft and World of Warcraft, reading, and
      watching a lot of movies. Discovered Linux at 14, watched Hackers-1995
      soon thereafter, and the rest is history. I went to the University of Utah
      and got my Bachelor of Science in Computer Science. While I was there, I
      did
      grant-funded research for
      the{' '}
      <Link href="https://www.flux.utah.edu/" variant="styles.a" target="__blank" mdxType="Link">
        FLUX
      </Link>{' '}
      Research Group in the areas of Systems and Security, mostly focusing on
      Fuzzers, including static analysis for fuzzing campaigns, fuzzing WASM
      runtimes, and distributed compute applied to fuzzing. I wrote 
      my{' '}
      <Link href="https://www.flux.utah.edu/paper/lremes-thesis" variant="styles.a" target="__blank" mdxType="Link">
        Thesis 
      </Link>{' '}
      on a new distributed Fuzzer architecture 
      called{' '}
      <Link href="https://github.com/Cybergenik/hopper" variant="styles.a" target="__blank" mdxType="Link">
        Hopper
      </Link>{' '}
      under the guidance of Professor{' '}
      <Link href="https://ricci.io/" variant="styles.a" target="__blank" mdxType="Link">
        Robert Ricci
      </Link>.<br /><br />
      I've been working in industry since I was 18, during my undergrad I
      worked at a few startups including Blerp and Solo. I also did Software
      Engineering internships at Goldman Sachs in their SPARC Automation team
      and at Amazon on the AWS EC2 Security Trust Infrastructure team. Most of
      my work in industry has focused on building infrastructure and scalable
      systems. Currently, I'm working on distributed network infrastructure
      at{' '}
      <Link href="https://www.palantir.com/" variant="styles.a" target="__blank" mdxType="Link">
        Palantir Technologies
      </Link>.
    </Text>
  </Box>
    </Grid>
    <h2><a parentName="h2" {...{
        "href": "#goal"
      }}>{`Goal`}</a>{`:`}</h2>
    <p>{`I enjoy working with curious people, driven to create the future of technology.
I strive to be better every day and look for people who share that drive.`}</p>
    <h2><a parentName="h2" {...{
        "href": "#blogs"
      }}>{`Blogs`}</a></h2>
    <p>{`I update my blog as much as my free time will alow me, and I treat my blogs as a
candid place to express ideas. Basically just a brain dump of what I'm thinking
at the time, and occasionally I'll write a high effort post. `}</p>
    <p><strong parentName="p">{`All opinions expressed are my own`}</strong>{`, and no one elses (including, but
especially my employer). If I have anything new going on I'll probably put it in
the home page (like a new blog post). Otherwise, besides from my blog posts I
might also post new tech and tools I'm using in `}<a parentName="p" {...{
        "href": "/#recs"
      }}>{`recommendations`}</a>{` from time
to time.`}</p>
    <p>{`Here are some stats on my blog posts:`}</p>
    <SourceWords mdxType="SourceWords">
  {source => {
        const {
          wordCountTotal,
          wordCountAverage,
          wordCountHighest,
          wordCountLowest,
          timeToReadTotal,
          timeToReadAverage
        } = source;
        return <Flex sx={{
          flexWrap: 'wrap',
          mx: theme => `-${theme.space[2]}px`
        }} mdxType="Flex">
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            px: 2,
            width: ['100%', '50%', '33.333333333%']
          }} mdxType="Box">
          <Flex sx={{
              alignItems: 'center',
              backgroundColor: 'surface',
              flex: '1 1 auto',
              flexDirection: 'column',
              justifyContent: 'center',
              p: 3,
              position: 'relative'
            }} mdxType="Flex">
            <Heading as="h4" variant="styles.h4" mdxType="Heading">
              Average word count
            </Heading>
            <Donut sx={{
                mx: 3,
                mb: 2
              }} value={wordCountAverage / wordCountTotal} mdxType="Donut" />
            <Box sx={{
                position: 'absolute'
              }} mdxType="Box">
              <Text sx={{
                  textAlign: 'center',
                  color: 'primary',
                  fontSize: '22px',
                  fontWeight: 'bold',
                  lineHeight: '1'
                }} mdxType="Text">
                {wordCountAverage}
              </Text>
              <Text sx={{
                  textAlign: 'center',
                  color: 'primary',
                  lineHeight: '1'
                }} mdxType="Text">Words</Text>
            </Box>
            <Text sx={{
                textAlign: 'center'
              }} mdxType="Text">{`Total words: ${wordCountTotal}`}</Text>
          </Flex>
        </Box>
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            px: 2,
            width: ['100%', '50%', '33.333333333%']
          }} mdxType="Box">
          <Flex sx={{
              alignItems: 'center',
              backgroundColor: 'surface',
              flex: '1 1 auto',
              flexDirection: 'column',
              justifyContent: 'center',
              p: 3,
              position: 'relative'
            }} mdxType="Flex">
            <Heading as="h4" variant="styles.h4" mdxType="Heading">
              Average time to read
            </Heading>
            <Donut variant="styles.donut.secondary" sx={{
                mx: 3,
                mb: 2
              }} value={timeToReadAverage / timeToReadTotal} mdxType="Donut" />
            <Box sx={{
                position: 'absolute'
              }} mdxType="Box">
              <Text sx={{
                  textAlign: 'center',
                  color: 'secondary',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  lineHeight: '1'
                }} mdxType="Text">
                {timeToReadAverage}
              </Text>
              <Text sx={{
                  textAlign: 'center',
                  color: 'secondary',
                  lineHeight: '1'
                }} mdxType="Text">Minute</Text>
            </Box>
            <Text sx={{
                textAlign: 'center'
              }} mdxType="Text">{`Total read time: ${timeToReadTotal} mins`}</Text>
          </Flex>
        </Box>
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            px: 2,
            width: ['100%', '50%', '33.333333333%']
          }} mdxType="Box">
          <Flex sx={{
              alignItems: 'center',
              backgroundColor: 'surface',
              flex: '1 1 auto',
              flexDirection: 'column',
              justifyContent: 'center',
              p: 3,
              position: 'relative'
            }} mdxType="Flex">
            <Heading as="h4" variant="styles.h4" mdxType="Heading">
              Highest word count
            </Heading>
            <Donut variant="styles.donut.success" sx={{
                mx: 3,
                mb: 2
              }} value={wordCountHighest / wordCountTotal} mdxType="Donut" />
            <Box sx={{
                position: 'absolute'
              }} mdxType="Box">
              <Text sx={{
                  textAlign: 'center',
                  color: 'success',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  lineHeight: '1'
                }} mdxType="Text">
                {wordCountHighest}
              </Text>
              <Text sx={{
                  textAlign: 'center',
                  color: 'success',
                  lineHeight: '1'
                }} mdxType="Text">Words</Text>
            </Box>
            <Text sx={{
                textAlign: 'center'
              }} mdxType="Text">{`Total words: ${wordCountTotal}`}</Text>
          </Flex>
        </Box>
      </Flex>;
      }}
    </SourceWords>
    <h2><a parentName="h2" {...{
        "href": "#genesux"
      }}>{`Genesux`}</a></h2>
    <Grid sx={{
      mb: 4
    }} mdxType="Grid">
  <Image src={`/images/banner_unix.png`} sx={{
        objectFit: 'cover'
      }} mdxType="Image" />
    </Grid>
    <p>{`By the time I was 14, I was using
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Internet_Relay_Chat"
      }}>{`IRC`}</a>{`. But it all started
when I was 12, I was entranced by online game forums, which would sometimes
bring up the topics of game design and development, slowly leading me to the
question of "How does a computer run my game?". I found myself eagerly curious
as to how we made computers enact our human vision, a curiosity I still have
today.`}</p>
    <p>{`Eventually I started using Linux after a user in a Debian IRC gleefully
mentioned a new release for an Operating System that was "revolutionary", it had
all this exciting new software to make the linux desktop better, a web engine
library called `}<a parentName="p" {...{
        "href": "https://launchpad.net/oxide"
      }}>{`Oxide`}</a>{`. Knowing nothing about these
arcane technologies, I asked if he could show me how to install this "Ubuntu"
thing. For the next 4 hours he proceeded to show me the Ubuntu install page and
walked me through how to dual-boot it on my old Dell desktop family computer, as
well as showing me how to use the apt package manager. At the 4 hour mark, my
father barged in, immediately bewildered by the orange background and goat-like animal on
the family desktop, recognizing that this wasn't the same desktop he saw that
morning, quickly rushed to the keyboard while telling me I had installed a
virus. After careful mitigation, rebooting to windows, and reassuring him that
it wasn't a virus, I had successfuly averted a disasterous beating. I had
installed Ubuntu 14.04 - Trusty Tahr, somehow not discouraged by merely dodging
a beating, I quickly rebooted back to Ubuntu and began to explore this new
orange world. The logo looked cool, a small trivial observation that intruiged
my 14 year old impressionable mind, I clicked through all the apps, and found my
way to terminal. And that's the story of how a helpful Ubuntu evangelist, a web
engine library, and an orange Tahr came together to influence my career choice.`}</p>
    <p>{`Ever Since then I've been using Linux as my main Operating System, only
switching back to windows (dualboot or virt) to game. Fast forward a couple
years, I like the flexability and the freedom of customizability that linux has.
This is what my linux journey looked like:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Ubuntu -> Debian -> Arch -> Manjaro -> Gentoo -> Void -> Arch -> Debian.`}</p>
    </blockquote>
    <p>{`Yes, although briefly, I used Gentoo and it was a pain to use. Thankfully it was
only over part of a summer. So it's fair to say I've had a taste of the linux
ecosystem. But now we arrive at my current system:`}</p>
    <Grid style={{
      "marginLeft": "auto",
      "marginRight": "auto"
    }} sx={{
      mb: 4
    }} mdxType="Grid">
  <Image src={`/images/system.png`} sx={{
        objectFit: 'cover'
      }} mdxType="Image" />
    </Grid>
    <p>{`I don't use a `}<a parentName="p" {...{
        "href": "https://wiki.archlinux.org/title/desktop_environment"
      }}>{`desktop
environment`}</a>{`, I prefer to
use a heavily riced window manager called `}<a parentName="p" {...{
        "href": "https://awesomewm.org/"
      }}>{`awesome`}</a>{`
(AWM). I also use `}<a parentName="p" {...{
        "href": "https://wiki.archlinux.org/title/rxvt-unicode"
      }}>{`urxvt`}</a>{` as my
terminal, mostly becuase I hate bloated terminals, and urxvt is `}<strong parentName="p">{`small`}</strong>{`,
`}<strong parentName="p">{`performant`}</strong>{`, and best of all - `}<strong parentName="p">{`highly customizable`}</strong>{`. I try to use (as much
as I can) `}<a parentName="p" {...{
        "href": "https://suckless.org/philosophy/"
      }}>{`suckless`}</a>{` software, as the design
ethos makes sense. I try to keep my system lean, however I do like to test new
tools frequently so I end up collecting junk I don't use. If
`}<a parentName="p" {...{
        "href": "https://nixos.org/"
      }}>{`Nix`}</a>{` gets better docs I might switch, but otherwise I like
my Debian unstable setup.`}</p>
    <h2><a parentName="h2" {...{
        "href": "#site"
      }}>{`This Site`}</a>{`:`}</h2>
    <p><em parentName="p">{`Click for indepth stats`}</em></p>
    <Grid sx={{
      mb: 3
    }} mdxType="Grid">
  <Link href="https://googlechrome.github.io/lighthouse/viewer/?gist=dfccbae1f87cf4f4a5e7f5795b447f4e" target="__blank" mdxType="Link">
    <Image src={`/images/perf.png`} sx={{
          objectFit: 'cover'
        }} mdxType="Image" />
  </Link>
    </Grid>
    <h2><a parentName="h2" {...{
        "href": "#socials"
      }}>{`Socials`}</a></h2>
    <h3>{`Twitch:`}</h3>
    <p>{`You might find me streaming on Twitch. Usually side `}<a parentName="p" {...{
        "href": "https://github.com/Cybergenik/hopper"
      }}>{`projects`}</a>{`, occasionally `}<a parentName="p" {...{
        "href": "https://adventofcode.com/about"
      }}>{`Advent of Code`}</a>{`.`}</p>
    <Twitch channel="cybergenik" parent="www.lremes.com" mdxType="Twitch" />
    <h3>{`Twitter:`}</h3>
    <p>{`I tweet about whatever project I'm working on at the time. After the Twitter
acquisition, I've been more active on Twitter. Not sure if I actually enjoy it
or I'm just curious when it's all gonna fall apart.`}</p>
    <Grid style={{
      "marginLeft": "auto",
      "marginRight": "auto"
    }} mdxType="Grid">
  <TwitterTimeline username="Cybergenik" theme="dark" height={500} width={600} mdxType="TwitterTimeline" />
    </Grid>
    <h3>{`Mastodon:`}</h3>
    <p>{`I have a mastodon account on discuss.systems, usually the same kind of posts as my twitter: `}<a parentName="p" {...{
        "href": "https://discuss.systems/@lremes"
      }}>{`https://discuss.systems/@lremes`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      